/* .prod-title{
    width: 100%;

  
  display: flex;
  flex-wrap: wrap;
  text-overflow: ellipsis;
} */

.p


.block-with-text {
    word-break: break-all;
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 3em;
    text-align: justify;
    padding-right: 7px;
}
.block-with-text:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
}
.block-with-text:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
}

.prod-card-container{
    width: 24%;
}

.prod-img{
    height: 150px;
    object-fit: cover; 
}

@media only screen and (min-width: 768px) {
    .prod-img{
        height: 250px;
        object-fit: cover; 
    }    
}