.hero-container{
    width: 537px;
}

.button-infos{
    background: #FFA800;
}

.button-infos:hover{
    background: #FFA800;
}


.features-image:hover{
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .header-brand{
        display: none;
    } 
}
  