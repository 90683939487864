.main-shops-card-container{
    justify-content: start;
}

@media only screen and (max-width: 798px) {
    .main-shops-card-container{
        justify-content: space-between;
    }
}

