.nav-conatiner{
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 50;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.home-active{
    background-color: rgba(255, 168, 0, 0.1);
}

.shops-active{
    background-color: rgba(255, 255, 255, 0.1);
}

.saved-active{
    background-color: rgba(246, 78, 96, 0.1);
}

.cart-active{
    background-color: rgba(27, 197, 189, 0.1);
}

.account-active{
    background-color: rgba(137, 80, 252, 0.1);
}

.cart-tag{
    background-color: rgb(27, 197, 189);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: #ffffff;
    min-width: 20px;
    height: 20px;
    padding: 5px;
    border-radius: 50%;
    margin-right: -40px;
    margin-top: -28px;
}

span{
    font-size: x-small;
    display:block;
    width:100%;
}

.icon-text-wrapper{
    width: 50px;
    height: 50px;
    line-height: 15px;
    border-radius: 12px;
}