.nav-item:hover{
    cursor: pointer;
}

.nav-item{
    padding-left: 10px;
    padding-right: 10px;
}

.nav-text{
    font-size: 1.7rem;
}