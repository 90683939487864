.wizard.wizard-1 .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
    color: #B5B5C3;
    font-size: 3.75rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #B5B5C3;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    color: #7E8299;
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0.75rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
    color: #7E8299;
    font-size: 1.25rem;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
    display: none;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-icon, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-icon {
    color: #8950FC;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-icon svg g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-icon svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #8950FC;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-icon svg:hover g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-icon svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-label .wizard-title, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-title {
    color: #8950FC;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-arrow, .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-arrow {
    color: #8950FC;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-arrow svg g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-arrow svg g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
    fill: #8950FC;
  }
  .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=done] .wizard-arrow svg:hover g [fill], .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-arrow svg:hover g [fill] {
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }
  
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
      font-size: 3.75rem;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
      margin-top: 0.75rem;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
      font-size: 1.1rem;
    }
  }
  @media (max-width: 767.98px) {
    .wizard.wizard-1 .wizard-nav .wizard-steps {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: horizontal;
      -ms-flex-direction: row;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step {
      width: 50%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-left: 0;
      margin-right: 0;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-icon {
      font-size: 1.5rem;
      margin-right: 1.25rem;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow {
      font-size: 1rem;
    }
    .wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-arrow.last {
      display: block;
    }
  }

  .mpesalogo{
    width: 100px;
  }